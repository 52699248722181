body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import '~antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Toyota";
  src: url("./assets/fonts/ToyotaType-Regular.ttf");
}
@font-face {
  font-family: "Lobster";
  src: url("./assets/fonts/Lobster-Regular.ttf");
}